import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import ReactPlayer from "react-player";
import { GatsbyImage } from "gatsby-plugin-image";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const showVid = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BgWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  z-index: -11;
  
  &.show {
    opacity: 1;
  }
  &.hideOverlay{
    &::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;
  }
  }


  container-type: size;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 56.25vw;

    @container (min-aspect-ratio: 16/9) {
      height: 56.25vw !important;
      width: 100vw !important;
    }

    @container (max-aspect-ratio: 16/9) {
      width: 177.78vh !important;
      height: 100vh !important;
    }

    pointer-events: none;
  }



  @media (max-width: ${mediaQuery.tablet}px) {


  }
`;

const ThumbnailImage = styled(GatsbyImage)`
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 1;
  transition: opacity 0.3s ease;
  &.hidden { opacity: 0; }
`;

const BgVideo = ({ vimeo, thumbnailImage, show, hideOverlay }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [curentSeconds, setCurentSeconds] = useState(0);

  useEffect(() => {
    setIsVideoPlaying(false);
    setCurentSeconds(0);
  }, [vimeo]);

  const handleProgress = (state) => {
    setCurentSeconds(state.playedSeconds);
    if (!isVideoPlaying && state.playedSeconds > 0.1) {
      setIsVideoPlaying(true);
    }
  };

  return (
    <BgWrapper className={`${show && "show"} ${hideOverlay && "hideOverlay"}`}>
      <ReactPlayer
        url={vimeo}
        playsinline
        className="react-player desktop"
        muted
        playing={true}
        loop={true}
        onProgress={handleProgress}
        config={{
          vimeo: {
            playerOptions: {
              dnt: true,
              background: 1,
            },
          },
        }}
      />

      {thumbnailImage && (
        <ThumbnailImage
          className={isVideoPlaying ? "hidden" : ""}
          image={thumbnailImage.localFile.childImageSharp.gatsbyImageData}
        />
      )}
    </BgWrapper>
  );
};

export default BgVideo;
