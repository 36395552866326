import React, { useContext, useEffect, useState } from "react";
import { ContextProviderComponent, AppContext } from "src/layouts/Context.js";
import { useStaticQuery, graphql } from "gatsby";

import GlobalStyle from "src/assets/styles/globalStyles.js";
import BgVideo from "src/components/global/BgVideo.js";
// import Cookies from "src/components/global/Cookies.js";

import styled from "styled-components";

const TopTypeWrapper = styled.div`
  height: ${({ height }) => height}rem;
  z-index: -11;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  opacity: 0;
  > div {
    position: sticky;
    top: 0;
  }
  &::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;
  }
  &.show {
    opacity: 1;
  }
`;

const Index = ({ children, curentVid, typeId }) => {
  const context = useContext(AppContext);
  const [vimeoData, setVimeoData] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [vimeoDataSecondary, setVimeoDataSecondary] = useState(null);
  const [thumbnailImageSecondary, setThumbnailImageSecondary] = useState("");
  const [headerTopHeight, setHeaderTopHeight] = useState(0);

  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiWorkTopType(   sort: { Order: ASC } filter: {locale: {eq: "en"}}) {
     
      

     edges {
      node {
        List_services {
          Viemo_link
           Video_thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        id
      }
    }
      }
    }
  `);

  useEffect(() => {
    if (context?.data?.vimeo) {
      setVimeoData(context.data.vimeo);
    }
    if (context?.data?.thumbnailImage) {
      setThumbnailImage(context.data.thumbnailImage);
    }
    if (context?.data?.vimeoSecondary) {
      setVimeoDataSecondary(context.data.vimeoSecondary);
    }
    if (context?.data?.thumbnailImageSecondary) {
      setThumbnailImageSecondary(context.data.thumbnailImageSecondary);
    }
    if (context?.data?.headerTopHeight !== undefined) {
      setHeaderTopHeight(context.data.headerTopHeight);
    }
  }, [
    context?.data?.vimeo,
    context?.data?.thumbnailImage,
    context?.data?.vimeoSecondary,
    context?.data?.thumbnailImageSecondary,
    context?.data?.headerTopHeight,
  ]);

  return (
    <>
      <GlobalStyle />

      {/* Show only the last item from the edges array */}
      {data.allStrapiWorkTopType.edges.slice(-1).map((e, index) => {
        return (
          <TopTypeWrapper
            height={headerTopHeight}
            className={typeId == e.node.id && "show"}
          >
            {console.log(e.node.List_services[0].Viemo_link)}
            <BgVideo
              show={typeId == e.node.id}
              key={index}
              vimeo={e.node.List_services[0].Viemo_link}
              thumbnailImage={e.node.List_services[0]?.Video_thumbnail}
              hideOverlay={true}
            />
          </TopTypeWrapper>
        );
      })}

      <BgVideo
        key={vimeoData}
        show={curentVid == vimeoData}
        vimeo={vimeoData}
        thumbnailImage={thumbnailImage}
      />
      <BgVideo
        key={vimeoDataSecondary}
        show={curentVid == vimeoDataSecondary}
        vimeo={vimeoDataSecondary}
        thumbnailImage={thumbnailImageSecondary}
      />

      {children}
    </>
  );
};

// Wrap the export with the ContextProvide r instead
const WrappedIndex = ({ children, data, pageContext }) => (
  <ContextProviderComponent>
    <Index
      curentVid={data?.strapiWork?.Thumbnail_video_VIMEO}
      typeId={pageContext.id}
    >
      {children}
    </Index>
  </ContextProviderComponent>
);

export default WrappedIndex;
