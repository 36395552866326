import React, { createContext } from "react";

export const AppContext = createContext({
  data: {
    vimeo: null,
    thumbnailImage: null,
    vimeoSecondary: null,
    thumbnailImageSecondary: null,
    headerTopHeight: 0,
  },
  set: () => {},
});

export class ContextProviderComponent extends React.Component {
  constructor() {
    super();

    this.setData = this.setData.bind(this);
    this.state = {
      ...AppContext.Provider.value,
      set: this.setData,
    };
  }

  setData(newData) {
    this.setState((state) => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
